import React from "react"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/header/header"
import { useUserOrgs } from "../../../hooks/use-user"
import CounterNoticeCreate from "../../../components/dmca/counternotice/create"
import "../../../styles/metrics.scss"
import { Loading } from "../../../components/loading"
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs"

const CreatePage = () => {
  const {
    loading,
    currentOrg: { index },
  } = useUserOrgs()

  if (loading) {
    return <Loading />
  }
  return (
    <Layout pageTitle="DMCA counter notice">
      <Breadcrumbs breadcrumbs={[{ label: "DMCA" }]} />
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">DMCA counter notice</h1>
      </Header>
      <Content>
        <CounterNoticeCreate orgIndex={index} />
      </Content>
    </Layout>
  )
}

export default CreatePage
